import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { doc, updateDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";

const schema = yup
  .object({
    viikko: yup.string(),
    burger_1: yup.string(),
    burger_1_info: yup.string(),
    burger_2: yup.string(),
    burger_2_info: yup.string(),
    burger_3: yup.string(),
    burger_3_info: yup.string(),
    lisa_1: yup.string(),
    lisa_2: yup.string(),
    lisa_3: yup.string(),
  })
  .required();

const MenuForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    for (let item of Object.keys(data)) {
      if (data[item] === "") {
        delete data[item];
      }
    }
    console.log(data);
    try {
      await updateDoc(
        doc(getFirestore(), "lounas", "5wrL3Ub9vNiWhO7TOTcH"),
        data
      );
    } catch (ex) {
      console.log(ex);
    }
    reset();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p>viikko</p>
      <input {...register("viikko")} />
      <p>{errors.viikko?.message}</p>
      <p>burger 1</p>
      <input {...register("burger_1")} />
      <p>{errors.burger_1?.message}</p>
      <p>burger 1 info</p>
      <input {...register("burger_1_info")} />
      <p>{errors.burger_1_info?.message}</p>
      <p>burger 2</p>
      <input {...register("burger_2")} />
      <p>{errors.burger_2?.message}</p>
      <p>burger 2 info</p>
      <input {...register("burger_2_info")} />
      <p>{errors.burger_2_info?.message}</p>
      <p>burger 3</p>
      <input {...register("burger_3")} />
      <p>{errors.burger_3?.message}</p>
      <p>burger 3 info</p>
      <input {...register("burger_3_info")} />
      <p>{errors.burger_3_info?.message}</p>
      <p>lisä 1</p>
      <input {...register("lisa_1")} />
      <p>{errors.lisa_1?.message}</p>
      <p>lisä 2</p>
      <input {...register("lisa_2")} />
      <p>{errors.lisa_2?.message}</p>
      <p>lisä 3</p>
      <input {...register("lisa_3")} />
      <p>{errors.lisa_3?.message}</p>
      <input type="submit" />
    </form>
  );
};

export default MenuForm;
