import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { initializeApp } from "firebase/app";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const firebaseConfig = {
  apiKey: "AIzaSyBEaB8YhsH09kPMJroXUdqrtLvuwrFItXc",
  authDomain: "bites-lounas-dev.firebaseapp.com",
  projectId: "bites-lounas-dev",
  storageBucket: "bites-lounas-dev.appspot.com",
  messagingSenderId: "252113462766",
  appId: "1:252113462766:web:8ae04c168405f97f839aff",
  measurementId: "G-M5YY65N2FC",
};

export const app = initializeApp(firebaseConfig);
