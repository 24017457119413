import "./App.css";
import Login from "./components/login";
import { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";

import {
  setPersistence,
  signInWithEmailAndPassword,
  inMemoryPersistence,
} from "firebase/auth";

import MenuForm from "./components/menuForm";

function App() {
  const [auth, setAuth] = useState(null);
  useEffect(() => {
    const info = getAuth().currentUser;
    setAuth(info);
  }, []);

  const doSubmit = async (data) => {
    const { email, password } = data;
    try {
      const auth = getAuth();
      await setPersistence(auth, inMemoryPersistence);
      await signInWithEmailAndPassword(auth, email, password);
      setAuth(auth.currentUser);
    } catch (ex) {
      console.log(ex);
    }
  };
  return (
    <div>
      {auth && <MenuForm />}
      {!auth && <Login onSubmit={doSubmit} />}
    </div>
  );
}

export default App;
